<template>
    <div>
        <card-header title="Trips" icon="fa-plane"/>
        <card-toolbar>
            <button @click="$openCard('new-trip', {}, card)"><i class="fas fa-plus mr-2"/>New Trip</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Trips" icon="fas fa-plane-departure"></subheader-list-item>
                <trip-list-item
                    :trip="trip"
                    :card="card"
                    v-for="trip in trips"
                    :active="child && child.definition.component === 'trip' && child.props.tripId === trip.id.toString()"
                    @click="$openCard('trip', {tripId: trip.id}, card)"
                    tasks
                />
                <separator-list-item/>
                <navigation-list-item
                    icon="fa-plane-arrival"
                    title="Archived Trips"
                    :badge="archiveCount"
                    :active="child && child.definition.component === 'archived-trips'"
                    @click="$openCard('archived-trips', {}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";

    export default {
        props: ['card', 'child'],
        components: {CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                trips: [],
                archiveCount: 0
            };
        },
        methods: {
            loadTrips: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/active/0/500', {force}).then(response => {
                    this.trips = response.data.trips;
                    this.$emit('loading', false);
                });

                http.get('/api/trips/archived/0/0').then(response => {
                    this.archiveCount = response.data.count;
                });
            },
            reload: function() {
                this.loadTrips(true);
            }
        },
        mounted() {
            this.loadTrips();
        }
    };
</script>

<style lang="scss" scoped>

</style>
